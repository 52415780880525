<template>
  <div>
    <div class="card">
      <div class="card-block">
        <!-- пока выводим модалку только в wlc 
        upd: модальное окно и кнопка скрыты, потому что она никогда не работала, но для каких-то целей в прошлом требовалось отображать -->
        <!--
        <div v-if="isWLCProduct"
          class="additional-container"
        >
          <WButton info
            @click="openManageSyslogModal"
            :disabled="isLoading"
          >
            {{ $t('logs.manageServers') | capitalize }}
          </WButton>
        </div>
        -->
        <LogsTable :logs="logs">
          <template v-slot:table-menu>
            <ControlPanel
              :searchContainers="transformedFilterContainers"
              :isDisabled="isLoading"
              @submit="fetchLogs"
              @downloadTxt="downloadTxtFile"
            />
          </template>
        </LogsTable>
      </div>
    </div>
    <!--
    <Modal
        :title="$t('logs.manageServers')"
        class="modal-primary"
        v-model="manageSyslogModalOpened"
        okText="Confirm"
        cancelText="Cancel"
        @ok="handleOk"
        @cancel="handleCancel"
        effect="fade/zoom"
    >
      <h4 slot="modal-title" class="modal-title">{{$t('logs.manageServers')}}</h4>

      <div class="container container-fluid">
        <div class="card">
          <div class="card-block">
            <h6>{{$t('logs.levelSettings')}}</h6>
            <hr>
            <div class="form-group row">
              <label for="syslog" class="col-6 col-form-label">{{$t('logs.syslog')}}</label>
              <div class="col-6">
                <select id="syslog" class="form-control">
                  <option>{{$t('logs.informational')}}</option>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <label for="syslog" class="col-6 col-form-label">Message console</label>
              <div class="col-6">
                <select id="syslog" class="form-control">
                  <option>Unconfigured</option>
                </select>
              </div>
            </div>
            <h6>{{$t('logs.messageBuffer')}}</h6>
            <hr>
            <div class="form-group row">
              <label for="syslog" class="col-6 col-form-label">{{$t('logs.level')}}</label>
              <div class="col-6">
                <select id="syslog" class="form-control">
                  <option>{{$t('logs.debugging')}}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-block">
            <h6>{{$t('logs.ipConfig')}}</h6>
            <hr>
            <div class="mb-2">
              <WButton info
                customClass="ml-auto mb-2"
              >
                {{ $t('general.add') }}
              </WButton>
              <div class="form-group row">
                <label class="col-6 col-form-label">{{$t('logs.serverType')}}</label>
                <div class="col-6">
                  <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="ip-fqdn" id="ip" value="ip">
                    <label class="form-check-label" for="ip">IPV4/IPV6</label>
                  </div>
                  <div class="form-check form-check-inline ml-3">
                    <input class="form-check-input" type="radio" name="ip-fqdn" id="fqdn" value="fqdn">
                    <label class="form-check-label" for="fqdn">FQDN</label>
                  </div>
                </div>
              </div>
              <div class="form-group row">
                <label for="syslog" class="col-6 col-form-label">{{$t('logs.ipv4ipv6address')}}</label>
                <div class="col-6">
                  <input type="text" class="form-control" placeholder="X.X.X.X / X:X:X::X">
                </div>
              </div>
              <div class="form-group row">
                <label for="syslog" class="col-6 col-form-label">{{$t('logs.vrfName')}}</label>
                <div class="col-6">
                  <select id="syslog" class="form-control">
                    <option>None</option>
                  </select>
                </div>
              </div>
            </div>
            <table class="table">
              <thead>
                <tr>
                  <th>{{$t('logs.server')}}</th>
                  <th>{{$t('logs.ipType')}}</th>
                  <th>{{$t('logs.vrfName')}}</th>
                  <th>{{$t('general.delete')}}</th>
                </tr>
              </thead>
              <tbody>
              </tbody>
            </table>
            <div class="d-flex justify-content-between">
              <nav>
                <ul class="pagination">
                  <li class="page-item">
                    <a class="page-link" href="javascript:void(0)" aria-label="Previous">
                      <span aria-hidden="true">&laquo;</span>
                    </a>
                  </li>
                  <li class="page-item"><a class="page-link" href="javascript:void(0)">1</a></li>
                  <li class="page-item">
                    <a class="page-link" href="javascript:void(0)" aria-label="Next">
                      <span aria-hidden="true">&raquo;</span>
                    </a>
                  </li>
                </ul>
              </nav>
              <div class="d-flex align-items-center per-page-wrapper">
                <select id="per-page" class="form-control">
                  <option>10</option>
                </select>
                <label for="per-page" class="mb-0 ml-1">{{$t('general.itemsPerPage')}}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div slot="modal-footer" class="modal-footer">
        <WButton secondary>
          {{ $t('general.apply') }}
        </WButton>
        <WButton danger outline @click="handleCancel">
          {{ $t('general.cancel') }}
        </WButton>
      </div>
    </Modal>
    -->
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import Modal from '@/components/Modal.vue';
import { getTextTable, transformLogs } from './utils';
import logsApiService from './api';
import {
  PREDEFINED_CONTAINERS,
  NOT_DISPALYED_FILTER_ITEMS_FOR_ONE,
  NOT_DISPALYED_FILTER_ITEMS_FOR_WLC,
  getDefaultFormValues,
  getFormValuesForByClientMACSearch
} from './config';

import {
  LogsTable,
  ControlPanel
} from './components';

import { workWithPassedByRouterParamClientMACMixin } from './mixins';

const { mapGetters: WLCProductMapGetters } = createNamespacedHelpers('WLCProduct');


export default {
  name: 'Logs',
  components: {
    Modal,
    LogsTable,
    ControlPanel
  },
  mixins: [ workWithPassedByRouterParamClientMACMixin ],
  inject: ['$validator'],
  filters: {
  },
  data() {
    return {
      //manageSyslogModalOpened: false,
      logs: [],
      containers: [],
      isLoading: false
    };
  },
  computed: {
    ...WLCProductMapGetters(['isWLCProduct']),
    transformedFilterContainers() {
      const PREDEFINED_CONTAINERS_NAMES = Object.keys(PREDEFINED_CONTAINERS).sort();

      const noSelectedContainersDefaultItem = {
        display: this.$t('logs.filter.noSelected'),
        data: ''
      };

      if (!Array.isArray(this.containers)) {
        return [
          noSelectedContainersDefaultItem,
          ...PREDEFINED_CONTAINERS_NAMES
            .filter(this.checkDisplayOrNotDisplayFilterListItemDependingOnBuild)
            .map(this.transformFilterListItemForShowInUI)
        ];
      }

      // примешиваем к пришедшим с бэка именам контейнеров, предопределенные имена контейнеров, которые
      // предопределены в конфиге и не приходят с бэка

      const resultArray = [
        noSelectedContainersDefaultItem,
        ...this.containers
          .concat(PREDEFINED_CONTAINERS_NAMES)
          .sort()
          .filter(this.checkDisplayOrNotDisplayFilterListItemDependingOnBuild)
          .map(this.transformFilterListItemForShowInUI)
      ];

      return resultArray;
    }
  },
  created() {
    this.FORM_VALUES = this.getFormValues();
  },
  mounted() {
    this.fetchContainers();
    this.fetchLogs(this.FORM_VALUES);
  },
  methods: {
    /**
     * Возвращает нужный formValue в зависимости от того нужен ли поиск с параметрами роута или нет
     * или нет
     *
     * @return {Object}
     */
    getFormValues() {
      if (this.passedByRouterParamClientMACValue) {
        return getFormValuesForByClientMACSearch(this.passedByRouterParamClientMACValue);
      }

      return getDefaultFormValues();
    },
    /**
     * Позволяет проверить показывать или нет пункт из списка фильтрации в зависимости от сборки
     * (Некоторые пункты не нужны в некоторых сборках)
     *
     * @param {String} filterListItem
     * @return {Boolean} отображаем или нет этот элемент
     */
    checkDisplayOrNotDisplayFilterListItemDependingOnBuild(filterListItem) {
      if (this.isWLCProduct) {
        return !NOT_DISPALYED_FILTER_ITEMS_FOR_WLC.includes(filterListItem);
      }
      return !NOT_DISPALYED_FILTER_ITEMS_FOR_ONE.includes(filterListItem);
    },
    /**
     * Функция, преобразующая элемент из списка опций для фильтрации в вид для отображения в
     * селкторе в UI (включая перевод)
     *
     * @param {String} filterListItem
     * @return {{data: String, display: String}}
     */
    transformFilterListItemForShowInUI(filterListItem) {
      return {
        display: this.$te(`logs.filter.${filterListItem}`) ? this.$t(`logs.filter.${filterListItem}`) : filterListItem,
        data: filterListItem
      };
    },
    handleCancel() {
      this.manageSyslogModalOpened = false;
    },
    handleOk() {},
    /*
    openManageSyslogModal() {
      this.manageSyslogModalOpened = true;
    },
    */
    async fetchLogs({
      limit,
      searchText,
      searchContainer,
      startDate,
      endDate
    }) {
      logsApiService.fetchLogs({
        searchText,
        searchContainer,
        limit,
        startDateTimestamp: startDate,
        endDateTimestamp: endDate
      }, {
        onLoading: () => {
          this.isLoading = true;
        },
        onSuccess: (logsData) => {
          this.isLoading = false;

          this.logs = transformLogs(logsData.result);
        },
        onError: () => {
          this.isLoading = false;
        }
      });
    },
    async fetchContainers() {
      const containersData = await logsApiService.fetchLabels('group');

      this.containers = containersData;
    },
    downloadTxtFile() {
      // Преобразование данных в текстовый формат с таблицей: header + body
      const txtData = getTextTable(this.logs);

      // Создание объекта Blob для текстового файла
      const blob = new Blob([txtData], { type: 'text/plain;charset=utf-8' });

      // Генерация URL для скачивания файла
      const url = window.URL.createObjectURL(blob);

      // Создание ссылки для скачивания файла
      const a = document.createElement('a');
      a.href = url;
      a.download = 'logs.txt';

      // Эмуляция клика на ссылку для скачивания
      a.click();

      // Освобождение ресурсов
      window.URL.revokeObjectURL(url);
    }
  }
};
</script>

<style lang="css" scoped>
.per-page-wrapper{
  select{
    width: 100px;
  }
  label{
    width: 100px;
    flex-grow: 1;
    flex-shrink: 0;
  }
}

.additional-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 16px;
}
</style>
